<template>
  <div class="spelling-detail">
    <div class="spelling-detail__header">
      <h2>Подробно</h2>
    </div>
    <div class="spelling-detail__items">
      <ul>
        <li>
          <h2>
            Орфографическая ошибка:
            <span class="gray">{{ detail.mistake }}</span>
          </h2>
        </li>
        <li>
          <h2>
            Описание:
            <span class="gray">{{ detail.description }}</span>
          </h2>
        </li>
        <li>
          <h2>
            Дата отправки:
            <span class="gray">
              {{ moment(detail.created_at).format("LLL") }}</span
            >
          </h2>
        </li>
        <li>
          <h2>
            Ссылка на ошибку:
            <a class="gray" :href="detail.page_url" target="_blank"> Перейти</a>
          </h2>
        </li>
        <li>
          <h2>
            Статус:
            <a-switch v-model="detail.fixed" @change="onFixed" />
          </h2>
        </li>
      </ul>
    </div>

    <div>
      <a-button
        class="spelling_btns"
        style="margin-top: 10px"
        type="primary"
        @click="$router.go(-1)"
      >
        <i class="t-transition icons icon-chevron-left" />
        {{ "Назад" }}
      </a-button>
    </div>
  </div>
</template>
<script>
export default {
  name: "SpellingDetail",
  data() {
    return {
      // columns,
      detail: []
    }
  },
  async mounted() {
    let res = await this.$api.get(
      `/admin/common/spellingmistake/${this.$route.params.id}/detail/`
    )
    console.log(res)
    this.detail = res.data
  },

  methods: {
    async onFixed() {
      let form = {
        fixed: this.detail.fixed
      }
      let res = await this.$api.patch(
        `/admin/common/spellingmistake/${this.$route.params.id}/detail/`,
        form
      )
      console.log(res)
      this.detail = res.data
    }
  }
}
</script>
<style>
.spelling-detail__header h2 {
  font-size: 30px;
  margin: 20px 0;
}
.spelling-detail__items ul li {
  padding: 10px 0;
}
.spelling-detail__items ul li span {
  padding-left: 10px;
}
.spelling_btns {
  display: flex;
  align-items: center;
}
.spelling_btns i {
  height: 15px;
  width: 15px;
}
.spelling-detail a:hover {
  color: #eb534d;
  text-decoration: underline;
}
.spelling-detail .gray {
  color: #9e9e9e;
}
</style>
